export default {
  goNextPageAutomatic: false,
  showQuestionNumbers: "off",
  pages: [
    {
      title: "Welchem Typ entsprechen Sie eher?",
      questions: [
        {
          type: "radiogroup",
          name: "WorkerType1",
          colCount: 2,
          title: "Spezialist oder Generalist",
          choices: ["Spezialist", "Generalist"],
          isRequired: true,
        },
        {
          type: "radiogroup",
          name: "WorkerType2",
          colCount: 2,
          title: "Netzwerker oder Motivator",
          choices: ["Netzwerker", "Motivator"],
          isRequired: true,
        },
        {
          type: "radiogroup",
          name: "WorkerType3",
          colCount: 2,
          title: "Wogenglätter oder Impulsgeber",
          choices: ["Wogenglätter", "Impulsgeber"],
          isRequired: true,
        }
      ]
    },
    {
      questions: [
        {
          type: "comment",
          name: "ChallengeAndHardwork",
          title: "Bitte beschreiben Sie so ausführlich wie möglich: Was war Ihre größte berufliche Herausforderung? Wie haben Sie diese gemeistert?",
          isRequired: true,
          validators: [{
            type: "expression",
            text: "Bitte geben Sie mindestens 150 Wörter ein.",
            expression: "WordCountMinValidator({ChallengeAndHardwork}, 150)"
          }],
        }
      ]
    },
    {
      title: "Wie sehr sind Sie von folgenden Aussagen überzeugt?",
      questions: [
        {
          type: "rating",
          name: "Tradition",
          title: "Ich möchte sicherstellen, dass ich keine Fehler mache.",
          isRequired: true,
          rateMin: 1,
          rateMax: 10,
          minRateDescription: "überhaupt nicht",
          maxRateDescription: "sehr/absolut"
        },
        {
          type: "rating",
          name: "Stimulation",
          title: "Nur wer etwas wagt, gewinnt!",
          isRequired: true,
          rateMin: 1,
          rateMax: 10,
          minRateDescription: "überhaupt nicht",
          maxRateDescription: "sehr/absolut"
        },
        {
          type: "rating",
          name: "Benevolenz",
          title: "Mir liegt am Herzen, dass meine Kollegen sagen, dass ich alles für sie gebe.",
          isRequired: true,
          rateMin: 1,
          rateMax: 10,
          minRateDescription: "überhaupt nicht",
          maxRateDescription: "sehr/absolut"
        },
        {
          type: "rating",
          name: "Konformitaet",
          title: "Erst die Arbeit, dann das Vergnügen.",
          isRequired: true,
          rateMin: 1,
          rateMax: 10,
          minRateDescription: "überhaupt nicht",
          maxRateDescription: "sehr/absolut"
        }, {
          type: "rating",
          name: "Hedonismus",
          title: "Man muss sich ab und zu auch mal etwas gönnen!",
          isRequired: true,
          rateMin: 1,
          rateMax: 10,
          minRateDescription: "überhaupt nicht",
          maxRateDescription: "sehr/absolut"
        }, {
          type: "rating",
          name: "Leistung",
          title: "Mir ist es nicht so wichtig, immer eine Top-Leistung zu zeigen. Erfolg ist manchmal Glück!",
          isRequired: true,
          rateMin: 1,
          rateMax: 10,
          minRateDescription: "überhaupt nicht",
          maxRateDescription: "sehr/absolut"
        }
      ]
    },
    {
      questions: [
        {
          type: "radiogroup",
          name: "Projektverlauf",
          title: "Stellen Sie sich vor, Sie planen ein neues Projekt. Welche Haltung vertreten Sie am stärksten?",
          choices: ["Wir sollten uns daran orientieren, was uns letztlich als Unternehmen voranbringt.",
            "Eine gute Recherche bringt uns neue Ideen und Impulse. Wir brauchen erst einmal eine gute Basis, bevor wir loslegen.",
            "Im Hinblick auf die Zeit sollten wir uns ganz pragmatisch organisieren."],
          isRequired: true,
        }
      ]
    },
  ]
};