import React from "react";
import logo from "../assets/logo.svg"

const SurveyHeaderDefaultProps = {
    title: 'Pfad-Check',
    desc: "Sie wissen nicht, welcher Entwicklungspfad der richtige für Sie sein könnte?\n Machen Sie den Pfad- Check und finden Sie es heraus!"
}

export type SurveyHeaderProps = typeof SurveyHeaderDefaultProps

const SurveyHeader = (props: Partial<SurveyHeaderProps>) => {
    const {title, desc} = {...SurveyHeaderDefaultProps, ...props}
    return (<div className="sv-title sv-container-modern__title">
        <div className="sv-logo sv-logo--left">
            <img className="sv-logo__image" src={logo} width="205px" height="60px" alt="logo"/>
        </div>
        <div className="sv-header__text">
            <h4 className="m-0">{title}</h4>
            <p className="font-weight-normal">
                {desc.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br/></span>
                })}
            </p>
        </div>
    </div>);
};

export default SurveyHeader