import {serialize} from 'object-to-formdata';

export const formSubmit = (url, params, target = '_blank', method = 'post') => {
  const form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", url);
  form.setAttribute("target", target);
  const formData = serialize(params);
  for (const [field, value] of formData) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = `${field}`;
    input.value = value;
    form.appendChild(input);
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export const getWordCount = text => text.split(/\s+/).length


export const WordCountMinValidator = ([text, minLength]) => {
  const wordCount = getWordCount(text)
  return wordCount >= minLength;
}