import React from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import SurveyHeader, {SurveyHeaderProps} from "./SurveyHeader";

const SurveyLayoutDefaultProps = {
    headerProps: {},
    includeCardInner: true
}

export type SurveyLayoutProps = {
    headerProps?: Partial<SurveyHeaderProps>
    children: JSX.Element
    includeCardInner: boolean
}

const SurveyLayout = (props: Partial<SurveyLayoutProps>) => {
    const {headerProps, includeCardInner, children} = {...SurveyLayoutDefaultProps, ...props}
    return <Container className="completed-page">
        <div className="d-none d-md-block mt-5"/>
        <Row className="mt-3">
            <Col>
                <Card>
                    <SurveyHeader {...headerProps}/>
                    {includeCardInner && <div className="card-inner mb-4 mt-2">{children}</div>}
                    {!includeCardInner && children}
                </Card>
            </Col>
        </Row>
    </Container>;
};

export default SurveyLayout;