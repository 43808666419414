import React, {useCallback, useEffect, useState} from "react";
import * as Survey from "survey-react";
import surveyData from "../assets/survey.js"
import "survey-react/modern.css";
import "../index.scss";
import {Button} from "react-bootstrap";
import {v4 as uuidv4} from 'uuid';
import {formSubmit, getWordCount, WordCountMinValidator} from "../utils/functions";
import SurveyLayout from "../components/SurveyLayout";


Survey
    .StylesManager
    .applyTheme("modern",'modern.sv-root-modern ');

Survey
    .FunctionFactory
    .Instance
    .register("WordCountMinValidator", WordCountMinValidator);

const survey = new Survey.Model(surveyData);
survey.logoPosition = "left";
survey.locale = "de";
survey.showProgressBar = 'bottom';
survey.showCompletedPage = false;

survey.onAfterRenderQuestion.add(function (survey, options) {
    if (options.question.getType() !== "comment") return;
    const textArea = options.htmlElement.getElementsByTagName('textarea')[0];
    // const desc = document.createElement("span");
    // desc.style.textAlign = "left";
    // desc.innerText = 'Bitte geben Sie mindestens 150 Wörter ein.';
    // textArea.parentNode.insertBefore(desc, textArea.nextSibling);

    const div = document.createElement("div");
    div.style.float = "right";
    textArea.parentNode.insertBefore(div, textArea.nextSibling);
    const changingHandler = function () {
        const currentWords = getWordCount(textArea.value);
        div.innerHTML = "Wörter: " + currentWords + "/" + 150;
    }
    changingHandler();
    textArea.addEventListener("input", changingHandler);
});


const SurveyPage = () => {
    const [completed, setCompleted] = useState(false)
    const [surveyResult, setSurveyResultDispatch] = useState('') // useLocalState for memorizing the result
    const [surveyStarted, setSurveyStarted] = useState(false);
    const setSurveyResult = (res) => setSurveyResultDispatch(JSON.stringify(res))
    const resetSurvey = () => {
        setSurveyResultDispatch('')
    }

    const handleComplete = (result) => {
        setSurveyResult({answers: result.data, correlationId: uuidv4()})
        setCompleted(true)
    }

    if (completed || surveyResult !== '') {
        return <CompletedPage surveyResult={JSON.parse(surveyResult)} resetSurvey={resetSurvey}/>
    }

    if (!surveyStarted) {
        return <StartPage startSurvey={setSurveyStarted}/>
    }

    return (
        <SurveyLayout includeCardInner={false}>
            <Survey.Survey model={survey} onComplete={handleComplete}
                           renderCompleted={CompletedPage}
                           renderStartPage={StartPage} completedHtml={CompletedPage}/>
        </SurveyLayout>
    );
}

const CompletedPage = ({surveyResult, resetSurvey}) => {
    const [firstTime, setFirstTime] = useState("1") // useLocalState for memorizing the result
    const [loading,setLoading] = useState(false)
    const handleSubmit = useCallback((payload) => {
        if(loading){
            return;
        }

        formSubmit((process.env.REACT_APP_BACKEND_URL || '') + '/api/v1/pfad-check', payload, '')
        setFirstTime("0")
        setLoading(true)
        window.setTimeout(() => setLoading(false),5000);
    }, [setFirstTime])

    useEffect(() => {
        firstTime === "1" && handleSubmit(surveyResult)
    }, [firstTime, handleSubmit, surveyResult])

    return <SurveyLayout
        headerProps={{desc: "Herzlichen Glückwunsch und vielen Dank für Ihre Teilnahme!"}}>
        <>

            <p className="mb-0">Probleme mit dem Download? Bitte benutzen Sie diesen&nbsp;<Button
                variant="link" className="inline-link"
                onClick={handleSubmit.bind(null, surveyResult)}>direkten
                Link</Button>&nbsp;zum Anzeigen des PDFs.</p>
            <p>
                <Button variant="link" className="inline-link" onClick={() => {
                    resetSurvey();
                    setFirstTime("1");
                }}>Klicken Sie hier</Button>, um den Pfad-Check erneut auszufüllen.
            </p>
            { loading &&
                <div className="text-center mt-2">
                    <div className="lds-roller">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                    <div>Ihr Pfad-Check wird geladen.</div>
                </div>
            }
        </>
    </SurveyLayout>
}


const StartPage = ({startSurvey}) => {
    return <SurveyLayout>
        <>
            <h3>Herzlich willkommen!</h3>
            <p>Haben Sie sich nicht auch schon einmal gefragt, ob eine Funktion auf Level 3 für Sie interessant sein könnte?
                Und wenn ja, in welchem Entwicklungspfad: Wissen, Führung oder doch lieber Projekt?
                Wo bin ich mit meinen Stärken und Interessen am besten aufgehoben?
                In welchem Pfad kann ich glänzen und mich weiterentwickeln?<br />
                <br />
                Bei Fragen wie diesen bietet der Pfad-Check Ihnen eine Orientierung.
                Ganz einfach und unkompliziert: Um Ihr persönliches Ergebnis zu erhalten,
                müssen Sie nur die nachfolgenden Fragen beantworten und einen kurzen Text verfassen.
                Das dauert maximal 5 Minuten.
                Den Rest übernimmt der Test für Sie: Auf Basis einer künstlichen Intelligenz wird
                Ihr Antwortverhalten mit den Ausrichtungen der Entwicklungspfade abgeglichen.
                Sie erhalten unmittelbar im Anschluss eine unverbindliche Übersicht, in welchem Pfad
                Sie tendenziell am besten aufgehoben sind und wo Ihre Stärken gut zum Einsatz kommen könnten.
                Die Teilnahme ist freiwillig und anonym. Es werden keinerlei Daten über Sie gespeichert.
                Das Ergebnis ist nur für Sie einsehbar – entscheiden Sie selbst, ob und mit wem Sie dies teilen wollen.</p>
            <div className="d-flex justify-content-center mt-4">
                <Button className={"start-survey-btn self-align-center"}
                        onClick={() => {
                            startSurvey(true);
                        }}>Pfad-Check jetzt starten!</Button>
            </div>
        </>
    </SurveyLayout>
}
export default SurveyPage;
