import React from 'react';
import SurveyPage from "./views/SurveyPage";
import './App.scss';
import {Redirect, Route, Router} from "react-router-dom";
import history from "./utils/history";

export default function App() {
    return (
        <Router history={history}>
            <Route path="/pfad-check" component={SurveyPage}/>
            <Route
                exact
                path="/"
                render={() => <Redirect to="/pfad-check"/>}
            />
        </Router>
    );
}